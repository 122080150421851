<template>
  <div>
    <requirement label="Requerimiento de Compras" :TypeRequirement=1></requirement>
  </div>
</template>
          

<script>
import Requirement from './Requirement.vue';
export default {
  name: "RequirementPurchase",
  components: { Requirement },
  props: {
   
  },
  data: () => ({
   
  }),

};
</script>